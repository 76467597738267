<template>
  <div class="page-container-alt">
    <h1 class="page-title">CCMP Templates</h1>

    <div class="template-group" v-for="(group, groupIndex) in templateGroups" :key="groupIndex">
      <h3>{{ group.name }}</h3>
      <a class="template-card" target="_blank" v-for="(template, templateIndex) in group.templates" :key="templateIndex" :href="template.url">
        <span class="material-icons template-card__icon">article</span>
        <span class="template-card__name">{{ template.name }}</span>
        <span class="template-card__task">{{ template.processStepName }}</span>
      </a>
    </div>

  </div>
</template>

<script>

export default {
  data () {
    return {}
  },

  computed: {
    lessons () {
      let proLessons = this.$store.state.ccmpData.lessons
      if (proLessons.length > 0) {
        // Remove lessons with id's 1 and 7 because they don't belong to the PRO lessons
        proLessons = proLessons.filter(lesson => {
          return !['1', '7'].includes(lesson.id)
        })

        // Rearrange items
        const order = ['8', '2', '3', '4', '5', '6', '9']
        const sortedProLessons = []

        order.forEach((lessonId) => {
          sortedProLessons.push(proLessons.find((lesson) => {
            return lesson.id === lessonId
          }))
        })

        proLessons = sortedProLessons
      }
      return proLessons
    },

    ccmpProTitles () {
      return this.$store.state.ccmpData.ccmpProTitles
    },

    templateGroups () {
      // Get all templates
      const templates = []
      this.$store.state.ccmpData.processSteps.forEach(processStep => {
        if (processStep.templates) {
          const links = this.extractLinksFromMarkdown(processStep.templates)
          if (links.length > 0) {
            links.forEach(link => {
              templates.push({
                name: link.name,
                url: link.url,
                processStepName: processStep.name,
                processStepId: processStep.id,
                processGroup: processStep.processGroup
              })
            })
          }
        }

        // Find processSubSteps with templates
        const processSubSteps = this.$store.state.ccmpData.processSubSteps.filter(processSubStep => {
          return processSubStep.processStepId[0] === processStep.id && processSubStep.templates
        })

        if (processSubSteps.length > 0) {
          processSubSteps.forEach(processSubStep => {
            const links2 = this.extractLinksFromMarkdown(processSubStep.templates)
            if (links2.length > 0) {
              links2.forEach(link => {
                templates.push({
                  name: link.name,
                  url: link.url,
                  processStepName: processSubStep.name,
                  processStepId: processSubStep.id,
                  processGroup: processStep.processGroup
                })
              })
            }
          })
        }
      })

      // Find all lessons with tasks attached
      const templateGroups = []
      this.lessons.forEach(lesson => {
        const lessonTemplates = []

        // Get lesson templates
        if (lesson.templates) {
          const links = this.extractLinksFromMarkdown(lesson.templates)
          if (links.length > 0) {
            links.forEach(link => {
              lessonTemplates.push({
                name: link.name,
                url: link.url,
                processGroup: lesson.id
              })
            })
          }
        }

        const filteredTemplates = templates.filter(template => {
          return template.processGroup === lesson.id
        })

        if (filteredTemplates.length > 0 || lessonTemplates.length > 0) {
          const allTemplates = lessonTemplates.concat(filteredTemplates)
          templateGroups.push({
            name: this.ccmpProTitles[lesson.id],
            templates: allTemplates
          })
        }
      })

      return templateGroups
    }
  },

  methods: {

    extractLinksFromMarkdown (markdown) {
      const links = []
      const re = /\[(.+)\]\((https?:\/\/[^\s]+)(?: "(.+)")?\)|(https?:\/\/[^\s]+)/ig
      const matches = [...markdown.matchAll(re)]

      if (matches) {
        matches.forEach(match => {
          links.push({
            name: match[1],
            url: match[2]
          })
        })
      }

      return links
    }
  }
}

</script>

<style lang="scss">
  .template-group {
    margin-bottom: 40px;
  }
  .template-card {
    position: relative;
    margin-bottom:  15px;
    background: #f8f8f8;
    padding: 15px 15px 15px 35px;
    cursor: pointer;
    text-decoration: none;
    display: block;
  }

  .template-card__name {
    font-size: 15px;
    line-height: 1.4;
    color: #1174BB;
    text-decoration: none;
    display: block;
    margin-bottom: 5px;
  }

  .template-card__task-title {
    color: #727272;
    font-size: 13px;
    line-height: 1.5;
    display: block;
  }

  .template-card__task {
    color: #393939;
    font-size: 13px;
  }

  .template-card__icon {
    font-size: 17px;
    position: absolute;
    color: #1174BB;
    left: 10px;
    top: 16px;
  }
</style>
